<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="dialogStatus"
      persistent
      content-class="warranty-dialog"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"
        >
          <template v-if="lodash.isEmpty(warrantyDetail) === false">
            Update Warranty
          </template>
          <template v-else> Add Warranty</template>
        </v-card-title>
        <v-card-text class="p-8 font-size-16">
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll"
            style="max-height: 90vh; position: relative"
          >
            <v-form
              ref="warrantyForm"
              v-model.trim="formValid"
              lazy-validation
              v-on:submit.stop.prevent="updateWarranty"
            >
              <v-container fluid>
                <v-row>
                  <template v-if="lodash.isEmpty(warrantyDetail)">
                    <!--  <v-col md="12" class="py-0 mb-2">
                      <v-radio-group
                        v-model="warrantyOption.warranty_type"
                        v-on:change="clearAll"
                        row
                        hide-details
                        class="mt-0"
                      >
                        <v-radio
                          style="margin-right: -2px"
                          value="customer"
                          color="blue"
                        ></v-radio>
                        <v-chip
                          label
                          color="blue"
                          outlined
                          class="text-white p-3 mr-5"
                          small
                          >Customer</v-chip
                        >
                        <v-radio
                          value="equipment"
                          color="red"
                          style="margin-right: -2px"
                        ></v-radio>
                        <v-chip
                          label
                          color="red"
                          outlined
                          class="text-white p-3"
                          small
                          >Equipment</v-chip
                        >
                      </v-radio-group>
                    </v-col> -->
                    <v-col
                      md="6"
                      class="py-0"
                      v-if="warrantyOption.warranty_type == 'customer'"
                    >
                      <label class="font-weight-600 ml-1">Customer</label>
                      <v-autocomplete
                        v-model.trim="warrantyOption.customer"
                        :items="allCustomerList"
                        clearable
                        dense
                        flat
                        filled
                        v-on:click:clear="clearAll"
                        label="Display Name"
                        item-color="cyan"
                        color="cyan"
                        solo
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        item-text="display_name"
                        item-value="id"
                        v-on:change="getOptions"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Customer(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <template>
                            <v-list-item-avatar>
                              <v-img
                                :lazy-src="$defaultProfileImage"
                                :src="$assetAPIURL(item.profile_logo)"
                                aspect-ratio="1"
                                class="margin-auto grey lighten-5 custom-grey-border"
                                transition="fade-transition"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                class="text-capitalize font-weight-500 font-size-16"
                                >{{ item.display_name }}
                                <v-chip
                                  color="blue white--text"
                                  label
                                  class="ml-2"
                                  v-if="
                                    item.company_type &&
                                    item.company_type == 'company'
                                  "
                                >
                                  <span class="font-size-16 font-weight-500">
                                    Company
                                  </span>
                                </v-chip>
                                <v-chip
                                  color="red white--text"
                                  label
                                  class="ml-2"
                                  v-if="
                                    item.company_type &&
                                    item.company_type == 'personal'
                                  "
                                >
                                  <span class="font-size-16 font-weight-500">
                                    Individual
                                  </span>
                                </v-chip>
                              </v-list-item-title>
                              <v-list-item-title
                                class="text-capitalize font-weight-500 font-size-16"
                                >{{ item.company_name }}</v-list-item-title
                              >
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      :md="warrantyOption.warranty_type == 'equipment' ? 12 : 6"
                      class="py-0"
                    >
                      <label class="font-weight-600 ml-1 required"
                        >Equipment</label
                      >
                      <v-autocomplete
                        v-model="warrantyOption.equipemt"
                        :items="allEquipemtList"
                        clearable
                        dense
                        flat
                        filled
                        label="Equipment"
                        item-color="cyan"
                        v-on:change="getEquipment($event)"
                        color="cyan"
                        solo
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        item-text="name"
                        item-value="id"
                        :rules="[
                          validateRules.required(
                            warrantyOption.equipemt,
                            'Equipment'
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title
                              v-html="'No Equipment(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-avatar left>
                            <v-img v-if="item.image" :src="item.image"></v-img>
                            <v-img v-else :src="$defaultImage"></v-img>
                          </v-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16 max-content-width"
                              >{{ item.name }}</v-list-item-title
                            >
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16 max-content-width"
                              ><Barcode :barcode="item.barcode"></Barcode>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </template>
                  <v-col md="12" v-if="warrantyOption.equipemt">
                    <v-layout class="nmt-1 bg-quo">
                      <v-flex md6 class="mr-2 custom-border-right">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Contact details</label>
                            </td>
                          </tr>
                          <tr
                            v-if="equipmemntArr && equipmemntArr.contact_person"
                          >
                            <td class="py-0">
                              <span
                                class="mr-2"
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.display_name
                                "
                              >
                                <v-icon small>mdi-account</v-icon></span
                              >
                              <label>{{
                                equipmemntArr.contact_person.display_name
                              }}</label>
                            </td>
                          </tr>
                          <em v-else class="text-muted ml-2">
                            no contact detail</em
                          >
                          <tr
                            v-if="equipmemntArr && equipmemntArr.contact_person"
                          >
                            <td class="py-0">
                              <span
                                class="mr-2"
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.primary_phone
                                "
                              >
                                <v-icon small>mdi-phone</v-icon></span
                              >
                              <label
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.primary_phone
                                "
                                >{{
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.primary_phone
                                }}</label
                              >
                              <em v-else class="text-muted">
                                no phone number</em
                              >
                            </td>
                          </tr>
                          <tr
                            v-if="equipmemntArr && equipmemntArr.contact_person"
                          >
                            <td class="py-0">
                              <span
                                class="mr-2"
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.primary_email
                                "
                              >
                                <v-icon small>mdi-email</v-icon></span
                              >
                              <label
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.primary_email
                                "
                                >{{
                                  equipmemntArr &&
                                  equipmemntArr.contact_person.primary_email
                                }}</label
                              >
                              <em v-else class="text-muted"> no email</em>
                            </td>
                          </tr>
                        </table>
                      </v-flex>
                      <v-flex md6 class="mr-2">
                        <table width="100%">
                          <tr>
                            <td class="font-weight-600">
                              <label>Company Address</label>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              equipmemntArr && equipmemntArr.property_address
                            "
                          >
                            <td class="py-0 d-flex">
                              <span
                                class="mr-2"
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.property_address
                                "
                              >
                                <v-icon small>mdi-map-marker</v-icon></span
                              >
                              <label
                                v-if="
                                  equipmemntArr &&
                                  equipmemntArr.property_address
                                "
                                >{{ equipmemntArr.street_1 }},
                                <br
                                  v-if="
                                    equipmemntArr.street_2 ||
                                    equipmemntArr.unit_no
                                  "
                                />
                                <template v-if="equipmemntArr.street_2">
                                  {{ equipmemntArr.street_2 }},
                                </template>
                                <template v-if="equipmemntArr.unit_no">
                                  {{ equipmemntArr.unit_no }},
                                </template>
                                <br />
                                {{ equipmemntArr.country }}
                                <template
                                  v-if="
                                    equipmemntArr.zip_code &&
                                    equipmemntArr.zip_code != '000000'
                                  "
                                >
                                  , {{ equipmemntArr.zip_code }}
                                </template>
                              </label>
                            </td>
                          </tr>
                          <em v-else class="text-muted ml-2">
                            no company address</em
                          >
                        </table>
                      </v-flex>
                    </v-layout>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Duration</label>
                    <v-text-field
                      filled
                      label="Duration"
                      solo
                      flat
                      color="cyan"
                      class="pr-2 width-100"
                      dense
                      v-model.trim="warrantyOption.duration"
                      v-mask="'###'"
                      v-on:keyup="
                        (e) => updateWarrantyDuration(e, 'warranty_duration')
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Duration Type</label>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      v-model.trim="warrantyOption.duration_type"
                      solo
                      flat
                      class="pl-2 pr-2 width-100"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      v-on:change="
                        (e) =>
                          updateWarrantyDuration(e, 'warranty_duration_type')
                      "
                    ></v-select>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Start Date</label>
                    <DatePicker
                      :defaultDate="warrantyOption.start_date"
                      solo
                      :pageLoading="pageLoading"
                      :placeholder="'Start Date'"
                      v-model="warrantyOption.start_date"
                      v-on:change="
                        (e) => updateWarrantyDuration(e, 'start_date')
                      "
                    ></DatePicker>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">End Date </label>
                    <DatePicker
                      :defaultDate="warrantyOption.end_date"
                      solo
                      :pageLoading="pageLoading"
                      :placeholder="'End Date'"
                      v-model="warrantyOption.end_date"
                      :min-date="warrantyOption.start_date"
                    ></DatePicker>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1 required"
                      >Serial No.</label
                    >
                    <v-text-field
                      dense
                      filled
                      :rules="[
                        validateRules.required(
                          warrantyOption.unique_id,
                          'Serial No.'
                        ),
                      ]"
                      color="cyan"
                      label="Serial No."
                      solo
                      flat
                      v-model.trim="warrantyOption.unique_id"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-600 ml-1">Cost</label>
                    <v-text-field
                      dense
                      filled
                      color="cyan"
                      solo
                      label="Cost"
                      flat
                      v-model.trim="warrantyOption.cost"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <label class="font-weight-600 ml-1">Description</label>
                    <v-textarea
                      v-model.trim="warrantyOption.description"
                      auto-grow
                      dense
                      filled
                      solo
                      flat
                      v-on:keypress="(e) => manageLimit(e)"
                      v-on:paste="(e) => onPaste(e)"
                      color="cyan"
                      label="Description"
                      row-height="20"
                    ></v-textarea>
                    <div class="text-right">
                      {{
                        warrantyOption.description
                          ? warrantyOption.description.length
                          : 0
                      }}/200
                    </div>
                  </v-col>
                  <v-col md="12" class="py-0">
                    <table
                      width="100%"
                      style="table-layout: fixed"
                      class="my-auto py-0 align-items-center"
                    >
                      <tr
                        v-for="(row, index) in files"
                        :key="`create-visit-attachment-${index}`"
                      >
                        <td class="">
                          <v-file-input
                            hide-details
                            class="px-0"
                            dense
                            filled
                            solo
                            flat
                            v-model="row.file"
                            placeholder="Select File"
                            prepend-icon=""
                            prepend-inner-icon="$file"
                            v-on:change="updateFile(index, $event)"
                            v-on:click:clear="updateFile(index, $event)"
                            color="cyan"
                          />
                        </td>
                        <td class="py-0">
                          <v-text-field
                            hide-details
                            class="px-0"
                            v-model="row.name"
                            placeholder="Name"
                            dense
                            filled
                            solo
                            flat
                            color="cyan"
                          />
                        </td>
                        <td class="px-0 py-0" width="5%" align="center">
                          <v-btn
                            :disabled="files.length < 2"
                            v-on:click="removeFile(index)"
                            icon
                            class="w-100"
                            color="red lighten-1"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="" colspan="3">
                          <v-btn
                            color="cyan white--text"
                            class="custom-bold-button"
                            depressed
                            v-on:click="addMore()"
                            tile
                            >Add More</v-btn
                          >
                        </td>
                      </tr>
                    </table>
                    <!--    <template v-for="(file, index) in files">
                      <v-row :key="index">
                        <v-col
                          lg="5"
                          cols="5"
                          class="my-auto py-0 align-items-center"
                        >
                          <v-file-input
                            :id="`document-file-${index}`"
                            placeholder="Select File"
                            outlined
                            class="mt-3"
                            prepend-icon=""
                            prepend-inner-icon="mdi-attachment"
                            hide-details
                            v-model="file.file"
                            v-on:change="updateFile(index, $event)"
                            v-on:click:clear="updateFile(index, $event)"
                          ></v-file-input>
                        </v-col>
                        <v-col
                          lg="5"
                          cols="5"
                          class="my-auto py-0  align-items-center"
                        >
                          <v-text-field
                            :id="`document-name-${index}`"
                            class="mt-3"
                            filled
                            placeholder="File Name1"
                            solo
                            flat
                            color="cyan"
                            hide-details
                            v-model="file.name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          lg="1"
                          cols="1"
                          class="my-auto py-0"
                          style="text-align: center"
                        >
                          <v-btn
                            :disabled="files.length < 2"
                            v-on:click="removeFile(index)"
                            color="red lighten-1 white--text"
                            class="mx-2"
                            icon
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col lg="1" cols="1" class="my-auto py-0">
                          <v-btn
                            class="mx-2 custom-bold-button white--text"
                            color="cyan"
                            tile
                            depressed
                            v-on:click="addMore()"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </perfect-scrollbar>
        </v-card-text>
        <v-divider class="my-0"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="warrant-manage-action">
            <v-btn
              :disabled="!formValid || pageLoading"
              :loading="pageLoading"
              v-on:click="update_or_create"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              >Save
            </v-btn>
            <v-btn
              :loading="pageLoading"
              :disabled="pageLoading"
              v-on:click="$emit('close:dialog')"
              class="mx-2 custom-grey-border custom-bold-button"
              >Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 18px;
  padding: 0;
}
.v-text-field--filled > .v-input__control > .v-input__slot {
  /*  min-height: 100%; */
}
</style>

<script>
import moment from "moment";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

import {
  POST,
  PUT,
  CLEAR_ERROR,
  QUERY,
  GET,
} from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { toSafeInteger, cloneDeep } from "lodash";
export default {
  name: "create-or-update-warranty",
  mixins: [ValidationMixin],
  props: {
    dialogStatus: {
      type: Boolean,
      default: false,
    },
    /*   allCustomerList: {
      type: Array,
      default: () => {
        return new Array();
      },
    },
    allEquipemtList: {
      type: Array,
      default: () => {
        return new Array();
      },
    }, */
    warrantyDetail: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  components: {
    DatePicker,
    Barcode,
  },
  watch: {
    dialogStatus: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param) {
          this.getAllCustomers();
          this.getCustomerEquipment();
          this.getOptions();
          this.updateWarrantyDuration(this.warrantyOption.start_date);
        }
      },
    },
  },
  data() {
    return {
      barcode: null,
      formValid: true,
      pageLoading: false,
      startDatePicker: null,
      defaultItemWarranty: new Array(),
      allCustomerList: [],
      equipmemntArr: {},
      allEquipemtList: [],
      files: [
        {
          file: null,
          name: null,
        },
      ],
      warrantyOption: new Object({
        id: null,
        customer: 0,
        equipemt: 0,
        cost: 0,
        warranty_type: "customer",
        start_date: moment().format("YYYY-MM-DD"),
        duration: 12,
        duration_type: "month",
        end_date: moment().format("YYYY-MM-DD"),
        unique_id: null,
        description: null,
      }),
      durationList: [
        {
          text: "Day",
          value: "day",
        },
        {
          text: "Week",
          value: "week",
        },
        {
          text: "Month",
          value: "month",
        },
      ],
      warrantyDurationTypes: [
        {
          text: "Day(s)",
          value: "day",
        },
        {
          text: "Month(s)",
          value: "month",
        },
        {
          text: "Year(s)",
          value: "year",
        },
      ],
      /*   warrantyList: [
        { text: "30 Days", value: "30", type: "day" },
        { text: "6 Months", value: "182", type: "day" },
        { text: "1 Year", value: "365", type: "day" },
        { text: "2 Years", value: "730", type: "day" },
        { text: "3 Years", value: "1095", type: "day" },
      ], */
      /*   warrantyDurationTypes: [
        { text: "Day(s)", value: "day" },
        { text: "Week(s)", value: "week" },
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ], */
    };
  },
  methods: {
    clearAll() {
      this.warrantyOption.customer = 0;
      this.warrantyOption.equipemt = 0;
      this.getAllCustomers();
      this.getCustomerEquipment();
    },
    updateWarrantyDuration(e, type) {
      let warranty_start_date = e;
      if (type != "start_date") {
        warranty_start_date =
          this.warrantyOption && this.warrantyOption.start_date
            ? this.warrantyOption.start_date
            : null;
      }
      if (warranty_start_date) {
        let date = moment(warranty_start_date, "YYYY-MM-DD");

        let duration = this.warrantyOption.duration
          ? toSafeInteger(this.warrantyOption.duration)
          : 0;
        switch (this.warrantyOption.duration_type) {
          case "day":
            date = moment(warranty_start_date, "YYYY-MM-DD").add(duration, "d");
            break;
          case "month":
            date = moment(warranty_start_date, "YYYY-MM-DD").add(duration, "M");
            break;
          case "year":
            date = moment(warranty_start_date, "YYYY-MM-DD").add(duration, "y");
            break;
        }
        /* this.$nextTick(() => {
					this.warranty.warranty_end_date = date.format("YYYY-MM-DD");
				}); */
        this.warrantyOption.end_date = cloneDeep(date.format("YYYY-MM-DD"));
        console.log(this.warrantyOption.end_date);
      }
    },
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.warrantyOption.description;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 199) {
        let trimValue = finalval.substring(0, 200);
        this.warrantyOption.description = trimValue;
        e.preventDefault();
      }
    },

    manageLimit(e) {
      if (
        this.warrantyOption.description &&
        this.warrantyOption.description.length > 199
      ) {
        e.preventDefault();
      }
    },
    updateFile(index, file, value, data) {
      if (value == "keyword") {
        if (file && file.name) {
          data[index].name = file.name.split(".").slice(0, -1).join(".");
          data[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          data[index].name = null;
          data[index].suffix = null;
        }
      } else {
        if (file && file.name) {
          this.files[index].name = file.name.split(".").slice(0, -1).join(".");
          this.files[index].suffix = `.${file.name.split(".").pop()}`;
        } else {
          this.files[index].name = null;
          this.files[index].suffix = null;
        }
      }
    },
    addMore() {
      this.files.push({
        file: null,
        name: null,
      });
    },

    removeFile(index) {
      this.files.splice(index, 1);
      if (this.files.length < 1) {
        this.addMore();
      }
    },
    getAllCustomers() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "customer-list" })
        .then(({ data }) => {
          _this.allCustomerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getOptions(id) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "warranty/options",
          data: { customer: id ? id : 0 },
        })
        .then(({ data }) => {
          _this.barcode = data;
          this.warrantyOption.unique_id = _this.barcode.barcode;
          if (id && id > 0) {
            _this.allEquipemtList = data.equipmentArr;
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getEquipment(id) {
      if (id && id > 0) {
        const _this = this;
        _this.$store
          .dispatch(QUERY, { url: `equipment/${id}` })
          .then(({ data }) => {
            this.equipmemntArr = data.property;
            if (data.customer) {
              this.warrantyOption.customer =
                data.customer.id && data.customer.id ? data.customer.id : null;
            }
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },
    getCustomerEquipment() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "equipment-list" })
        .then(({ data }) => {
          _this.allEquipemtList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    update_or_create() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }
      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      let formData = new FormData();
      formData.append(
        "type",
        this.warrantyOption.warrantyOption
          ? this.warrantyOption.warrantyOption
          : "customer"
      );
      formData.append(
        "type_id",
        this.warrantyOption.customer ? this.warrantyOption.customer : 0
      );
      formData.append(
        "service_id",
        this.warrantyOption.equipemt ? this.warrantyOption.equipemt : 0
      );
      formData.append(
        "duration",
        this.warrantyOption.duration ? this.warrantyOption.duration : 0
      );
      formData.append(
        "duration_type",
        this.warrantyOption.duration_type
          ? this.warrantyOption.duration_type
          : "day"
      );
      if (this.warrantyOption && this.warrantyOption.start_date) {
        formData.append(
          "start_date",
          this.warrantyOption.start_date ? this.warrantyOption.start_date : null
        );
      }
      if (this.warrantyOption && this.warrantyOption.end_date) {
        formData.append(
          "end_date",
          this.warrantyOption.end_date ? this.warrantyOption.end_date : null
        );
      }
      if (this.warrantyOption && this.warrantyOption.cost) {
        formData.append(
          "cost",
          this.warrantyOption.cost ? this.warrantyOption.cost : 0
        );
      }
      if (this.warrantyOption && this.warrantyOption.description) {
        formData.append(
          "remark",
          this.warrantyOption.description
            ? this.warrantyOption.description
            : null
        );
      }
      if (this.warrantyOption && this.warrantyOption.unique_id) {
        formData.append(
          "unique_id",
          this.warrantyOption.unique_id ? this.warrantyOption.unique_id : null
        );
      }
      for (let i = 0; i < this.files.length; i++) {
        if (this.files && this.files[i] && this.files[i].file) {
          formData.append(`file[${i}][file]`, this.files[i].file);
          formData.append(`file[${i}][name]`, this.files[i].name);
        }
      }

      let requestTYPE = POST;
      let requestURL = "warranty";
      this.$store
        .dispatch(requestTYPE, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    updateWarranty() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }

      _this.$store.dispatch(CLEAR_ERROR, {});
      _this.pageLoading = true;
      let formData = new Object({
        id: _this.lodash.toSafeInteger(_this.warrantyOption.id) || null,
        customer:
          _this.lodash.toSafeInteger(_this.warrantyOption.customer) || null,
        product:
          _this.lodash.toSafeInteger(_this.warrantyOption.product) || null,
        start_date: _this.warrantyOption.start_date,
        unique_id: _this.warrantyOption.unique_id,
        description: _this.warrantyOption.description,
        warranty_data: _this.defaultItemWarranty,
        duration: _this.defaultItemWarranty,
      });

      let requestType = POST;
      if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store
        .dispatch(requestType, { url: "warranty", data: formData })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
    /*  warrantDefaultStartDate() {
que_id,
        description: _this.warrantyOption.description,
        warranty_data: _this.defaultItemWarranty,
        duration: _this.defaultItemWarranty,
      });

      let requestType = POST;
      if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store
        .dispatch(requestType, { url: "warranty", data: formData })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
   /*  warrantDefaultStartDate() {
 if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store
        .dispatch(requestType, { url: "warranty", data: formData })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
   /*  warrantDefaultStartDate() {
   if (_this.lodash.toSafeInteger(formData.id) > 0) {
        requestType = PUT;
      }

      _this.$store
        .dispatch(requestType, { url: "warranty", data: formData })
        .then(() => {
          _this.$emit("update:warranty", true);
          _this.$emit("close:dialog");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  computed: {
   /*  warrantDefaultStartDate() {
      return this.lodash.isEmpty(this.warrantyDetail) === false
        ? this.warrantyDetail.warranty_start_date
        : new Date().toISOString().substr(0, 10);
    }, */
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
